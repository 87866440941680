<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_FST_CD"
            itemText="codeName"
            itemValue="code"
            label="상위구분"
            name="patrolFstCd"
            v-model="searchParam.patrolFstCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            codeGroupCd="PATROL_TYPE_SEC_CD"
            itemText="codeName"
            itemValue="code"
            label="하위구분"
            name="patrolSecCd"
            v-model="searchParam.patrolSecCd">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            label="추진부서"
            name="deptCd"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 추진기간 -->
          <c-datepicker
            :range="true"
            label="추진기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="searchParam.period"
          />
        </div>
      </template>
    </c-search-box>
    <!-- 순회점검 목록 -->
    <c-table
      ref="tasktable"
      title="LBL00001606"
      :columns="grid.columns"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      selection="multiple"
      rowKey="saiPatrolId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteTask" />
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addTask" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'constructionRelactionTaskSafetyCheck',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    task: {
      type: Object,
      default: () => ({
        insertUrl: '',
        deleteUrl: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
        {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'regDt',
            field: 'regDt',
            // 등록일
            label: '등록일',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolFstName',
            field: 'patrolFstName',
            // 상위구분
            label: '상위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolSecName',
            field: 'patrolSecName',
            // 하위구분
            label: '하위구분',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolName',
            field: 'patrolName',
            // 제목
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            // 부서
            label: '추진부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'patrolDate',
            field: 'patrolDate',
            // 추진일정
            label: '추진일정',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        startYmd: '',
        endYmd: '',
        period: [],
        sopConstructionId: '',
        constructionFlag: true,
      },
      editable: true,
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.patrol.list.url;
      // code setting
      this.$set(this.searchParam, 'sopConstructionId', this.popupParam.sopConstructionId)
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = 'LBL00001607'; // 순회점검 상세
      this.popupOptions.param = row;
      this.popupOptions.target = () => import(`${'@/pages/sai/patrol/patrolDetail.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    deleteTask() {
      let selectData = this.$refs['tasktable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택될 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.task.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(this.$_.map(selectData, (item) => {
                return {
                  sopConstructionId: this.popupParam.sopConstructionId,
                  saiPatrolId: item.saiPatrolId,
                }
              }))
            };
            this.$http.request((_result) => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$refs['tasktable'].$refs['compo-table'].clearSelection();
              this.getList();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addTask() {
      this.popupOptions.title = 'LBL00001608'; // 순회점검 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/patrol/patrolPop.vue'}`);
      this.popupOptions.isFull = false;
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTaskPopup;
    },
    closeTaskPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let insertList = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { saiPatrolId: item.saiPatrolId }) === -1) {
            insertList.push({
              sopConstructionId: this.popupParam.sopConstructionId,
              saiPatrolId: item.saiPatrolId,
            });
          }
        })

        this.$http.url = this.task.insertUrl;
        this.$http.type = 'POST';
        this.$http.param = insertList
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        },);
      }
    },
  }
};
</script>
